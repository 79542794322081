import type { FC } from 'react'

import { Layout } from '../components/Layout'
import { SEO } from '../components/Seo'
import { isOpenSource } from '../constants/config'
import { defaultBranchPath } from '../constants/strings'
import { use404 } from '../hooks/use404'
import { useSiteDetails } from '../hooks/useSiteDetails'

const NotFound: FC = () => {
  const { filePath, html, sourceUrl } = use404()
  const { author } = useSiteDetails()

  return (
    <Layout>
      <SEO
        description={`${author} is a frontend developer who likes React.`}
        title="404: Not found"
      />

      <section dangerouslySetInnerHTML={{ __html: html }} />

      {isOpenSource && (
        <p>
          <a
            href={`${sourceUrl}${defaultBranchPath}${filePath}`}
            rel="noopener noreferrer"
          >
            Suggest a change
          </a>
        </p>
      )}
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default NotFound
