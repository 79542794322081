import { graphql, useStaticQuery } from 'gatsby'

interface GQL404 {
  markdownRemark: {
    html: string
    fields: {
      path: string
    }
  }
  site: {
    siteMetadata: {
      sourceUrl: string
    }
  }
}

export const use404 = (): {
  filePath: string
  html: string
  sourceUrl: string
} => {
  const { markdownRemark, site } = useStaticQuery<GQL404>(graphql`
    {
      markdownRemark(
        frontmatter: { contentType: { eq: "not-found" } }
        fileAbsolutePath: { regex: "/pages/" }
      ) {
        html
        fields {
          path
        }
      }
      site {
        siteMetadata {
          sourceUrl
        }
      }
    }
  `)

  return {
    filePath: markdownRemark.fields.path,
    html: markdownRemark.html,
    sourceUrl: site.siteMetadata.sourceUrl,
  }
}
